* {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
}

body {
  /* margin: 0; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #242a28; */
  background: radial-gradient(#303b37, #1a1f1d);
  color: #586461;
}
